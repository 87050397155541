#captureArea {
  display: grid;
}

#video {
}

#canvas {
}

#notesCanvas {
  user-select: none;
  grid-column: 1;
  grid-row: 1;
  z-index: 2;
  margin: 0 auto;
}

#video {
  grid-column: 1;
  grid-row: 1;
  margin: 0 auto;
}

#photo {
  grid-column: 1;
  grid-row: 1;
  margin: 0 auto;
  display: none;
  background-color: gray;
  color: white;
  font-weight: bold;
}
